import UpsellModal from "../../admin/components/express-pos/express-checkout/components/UpSellModal";
import UpdateModal from "../../admin/components/express-pos/express-checkout/components/UpdateModal";
import IngoingCashChangeModal from "../../admin/IngoingCashChangeModal";
import { ComboDiscountModal } from "../../admin/components/discount-combo/ComboDiscountModal";
import { EnhancedReceiptModal } from "../../admin/components/poses/pos/components/ReceiptModal";
import { PosModificationsModal } from "../../admin/components/poses/shared/components/PosModificationsModal/PosModificationsModal";
import { AddCommentsModal } from "../../admin/components/poses/pos/components/AddCommentsModal";
import ConfirmOrderPickupTimeModal from "../../admin/components/order-display/ConfirmOrderPickupTimeModal";
import ModModal from "../../admin/components/express-pos/express-checkout/components/ModModal";
import { OnlineInfoModal } from "../../customer/shared/online/components/OnlineHeaderMessage/components/OnlineInfoModal";
import { TermsAndConditionsModal } from "../../common/components/TermsAndConditionsModal/TermsAndConditionsModal";
import PosInvoiceModal from "../../admin/components/poses/pos/components/PosInvoiceModal/PosInvoiceModal";
import { OrderAdvancedSettingsModal } from "../../admin/components/order-display/components/OrderAdvancedSettingsModal";
import { PhoneOrderModal, ActivePhoneOrdersModal } from "../../admin/components/poses/pos/components";
import { ConfirmCancelPayment } from "../../customer/pages/orderStatus/components";
import { OnlineOrderPickupModal } from "../../customer/pages/onlineOrder/components/OnlineOrderPickupModal/OnlineOrderPickupModal";
import { PuckModal } from "../../admin/components/poses/pos/components/PuckModal/PuckModal";
import { ExpressAddonModal } from "../../admin/components/express-pos/components/ExpressAddonModal";
import { AllergyModal } from "../../admin/components/poses/shared/components";
import { OnlineOrderResendConfirmationEmailModal } from "../../admin/components/online-orders/components/OnlineOrderResendConfirmationEmailModal";
import { ScaleModal } from "../../admin/components/poses/pos/components/ScaleModal/ScaleModal";
import { OnlineServicesModal } from "../../admin/components/order-display/components";
import { UserAccountCancelSubscriptionModal } from "../../customer/pages/userAccount/components/UserAccountSubscriptions/components";
import { AboutQoplaModal } from "../../admin/shared/components/AboutQoplaModal";
import { UserAccountSubscriptionInviteModal } from "../../customer/pages/userAccount/components/UserAccountSubscriptions/components/UserAccountSubscriptionInviteModal";
import { GiftCardModal } from "../../customer/pages/userAccount/components/UserAccountGiftCards/GiftCardModal";
import { ChangeSeatsAmountModal } from "../../admin/components/groupSubscriptions/components/ChangeSeatsAmountModal";
import { PosDigitalReceiptsModal } from "../../admin/components/poses/pos/components/PosDigitalReceiptsModal";
import { UserAccountSubscriptionUpgradeModal } from "../../customer/pages/userAccount/components/UserAccountSubscriptions/components/UserAccountSubscriptionUpgradeModal";
import { CreateUmbrellaCompanyModal } from "../../admin/components/company/components/CreateUmbrellaCompanyModal";
import { OnlineCookiesManagerModal } from "../../customer/pages/onlineOrder/components/OnlineCookies/OnlineCookiesManagerModal";
import { TableServiceModal } from "../../admin/components/express-pos/order-checkout/components/TableServiceModal";
import { GroupOnlineOrderCustomersModal } from "../../customer/pages/groupOnlineOrder/components/GroupOnlineOrderCustomersModal";
import { GroupOnlineOrderPaymentLockModal } from "../../customer/shared/groupOrder/components/GroupOnlineOrderPaymentLockModal";
import { DiscountAdminModal } from "../../admin/components/discount-admin/DiscountAdminModal";
import { FortnoxConnectionModal } from "../../admin/components/fortnox-register/FortnoxConnectionModal";
import { QoplaMessageModal } from "../../admin/components/super-admin/components";
import { PosForm } from "../../admin/components/pos-admin/components/PosForm";
import { ExpressForm } from "../../admin/components/pos-admin/components/ExpressForm";
import { CompanyUserBenefitsNetsPricesModal } from "../../admin/components/company-user-benefits/components/CompanyUserBenefitsNetsPricesModal";
import { TestModalOne, TestModalThree, TestModalTwo } from "./TestModals";
import { OnlineLastOrderIssuesModal } from "../../customer/pages/onlineOrder/components/OnlineLastOrders";
import { MergeInvoicesModal } from "../../admin/components/invoice/MergeInvoicesModal";
import { QMenuPreviewSignupModal } from "../../admin/components/menu-analysis/QMenuPreviewSignupModal";
import { EditInvoiceCustomerModal } from "../../admin/components/invoice/EditInvoiceCustomerModal";
import { ProductLibraryMenuUploadModal } from "../../admin/components/product/ProductLibraryMenuUploadModal";
import { QrTableBatchModal } from "../../admin/components/shop-settings/components/forms/components/QrTableBatchModal";
import { ExpressCommentModal } from "../../admin/components/express-pos-v2/components/shared/ExpressCommentModal";
import { ExpressConfirmationModal } from "../../admin/components/express-pos-v2/components/shared/ExpressConfirmationModal";
import { ExpressIdleModal } from "../../admin/components/express-pos-v2/components/shared/ExpressIdleModal";
import { CampaignInformationModal } from "../../admin/components/campaignAdmin/components/CampaignInformationModal";
import { CampaignPromotionModal } from "../../admin/components/campaignAdmin/components";
import { OnlineCheckoutDiscountModal } from "../../customer/pages/onlineCheckout/components/OnlineCheckoutDiscounts/components/OnlineCheckoutDiscountModal";
import { ExpressDiscountModal } from "../../admin/components/express-pos-v2/components/checkout/ExpressDiscountModal";

/**
 * If you trying to find where a certain modal is used and you ended up here.
 * 1. Go to `constants.ts`
 * 2. Find "modalNames"
 * 3. Find an entry matching your target modal
 * 3. Find references` on the corresponding key
 */

export type ModalDefinition = {
    name?: string;
    component: any;
};

/**
 * Perhaps in the future we should avoid importing all of the Modals into this file by typing the
 * interactions with ModalProvider like this:
 *    openModal<GiftCardModal>(props)
 */

export const modals: { [key: string]: ModalDefinition } = {
    testModalOne: {
        name: "testModalOne",
        component: TestModalOne
    },
    testModalTwo: {
        name: "testModalTwo",
        component: TestModalTwo
    },
    testModalThree: {
        name: "testModalThree",
        component: TestModalThree
    },
    "": {
        name: "none",
        component: null
    },
    giftCardModal: {
        component: GiftCardModal
    },
    upsellModal: {
        name: "upsellModal",
        component: UpsellModal
    },
    addonModal: {
        name: "addonModal",
        component: ExpressAddonModal
    },
    modModal: {
        name: "modModal",
        component: ModModal
    },
    updateModal: {
        name: "updateModal",
        component: UpdateModal
    },
    ingoingCashChangeModal: {
        component: IngoingCashChangeModal
    },
    discountComboModal: {
        component: ComboDiscountModal
    },
    posModificationsModal: {
        name: "posModificationsModal",
        component: PosModificationsModal
    },
    addCommentsModal: {
        name: "addCommentsModal",
        component: AddCommentsModal
    },
    receiptModal: {
        component: EnhancedReceiptModal
    },
    onlineOrderPickupModal: {
        component: OnlineOrderPickupModal
    },
    confirmOrderPickupTimeModal: {
        component: ConfirmOrderPickupTimeModal
    },
    posInvoiceModal: {
        component: PosInvoiceModal
    },
    onlineInfoModal: {
        component: OnlineInfoModal
    },
    termsAndConditionsModal: {
        name: "termsAndConditionsModal",
        component: TermsAndConditionsModal
    },
    orderAdvancedSettingsModal: {
        component: OrderAdvancedSettingsModal
    },
    phoneOrderModal: {
        component: PhoneOrderModal
    },
    activePhoneOrdersModal: {
        component: ActivePhoneOrdersModal
    },
    confirmCancelPaymentModal: {
        component: ConfirmCancelPayment
    },
    puckModal: {
        component: PuckModal
    },
    allergyModal: {
        component: AllergyModal
    },
    resendConfirmationEmailModal: {
        component: OnlineOrderResendConfirmationEmailModal
    },
    scaleModal: {
        component: ScaleModal
    },
    onlineServiceModal: {
        component: OnlineServicesModal
    },
    userAccountCancelSubscriptionModal: {
        component: UserAccountCancelSubscriptionModal
    },
    aboutQoplaModal: {
        component: AboutQoplaModal
    },
    subscriptionInviteModal: {
        component: UserAccountSubscriptionInviteModal
    },
    qMenuPreviewSignupModal: {
        name: "qMenuPreviewSignupModal",
        component: QMenuPreviewSignupModal
    },
    productLibraryMenuUploadModal: {
        name: "productLibraryMenuUploadModal",
        component: ProductLibraryMenuUploadModal
    },
    subscriptionUpgradeModal: {
        name: "subscriptionUpgradeModal",
        component: UserAccountSubscriptionUpgradeModal
    },
    changeSeatsAmountModal: {
        component: ChangeSeatsAmountModal
    },
    posDigitalReceiptsModal: {
        name: "posDigitalReceiptsModal",
        component: PosDigitalReceiptsModal
    },
    createUmbrellaCompanyModal: {
        name: "createUmbrellaCompanyModal",
        component: CreateUmbrellaCompanyModal
    },
    cookieManagerModal: {
        name: "cookieManagerModal",
        component: OnlineCookiesManagerModal
    },
    tableServiceModal: {
        name: "tableServiceModal",
        component: TableServiceModal
    },
    groupOnlineOrderCustomersModal: {
        name: "groupOnlineOrderCustomersModal",
        component: GroupOnlineOrderCustomersModal
    },
    groupOnlineOrderPaymentLockModal: {
        name: "groupOnlineOrderPaymentLockModal",
        component: GroupOnlineOrderPaymentLockModal
    },
    discountAdminModal: {
        name: "discountAdminModal",
        component: DiscountAdminModal
    },
    fortnoxConnectionModal: {
        name: "fortnoxConnectionModal",
        component: FortnoxConnectionModal
    },
    qoplaMessageModal: {
        name: "qoplaMessageModal",
        component: QoplaMessageModal
    },
    posAdminModal: {
        name: "posAdminModal",
        component: PosForm
    },
    expressPosAdminModal: {
        name: "expressPosAdminModal",
        component: ExpressForm
    },
    companyUserBenefitsNetsPricesModal: {
        name: "companyUserBenefitsNetsPricesModal",
        component: CompanyUserBenefitsNetsPricesModal
    },
    onlineLastOrderIssuesModal: {
        name: "onlineLastOrderIssuesModal",
        component: OnlineLastOrderIssuesModal
    },
    mergeInvoicesModal: {
        name: "mergeInvoicesModal",
        component: MergeInvoicesModal
    },
    editInvoiceCustomerModal: {
        name: "editInvoiceCustomerModal",
        component: EditInvoiceCustomerModal
    },
    qrTableBatchModal: {
        name: "qrTableBatchModal",
        component: QrTableBatchModal
    },
    expressCommentModal: {
        name: "expressCommentModal",
        component: ExpressCommentModal
    },
    expressConfirmationModal: {
        name: "expressConfirmationModal",
        component: ExpressConfirmationModal
    },
    expressIdleModal: {
        name: "expressIdleModal",
        component: ExpressIdleModal
    },
    campaignInformationModal: {
        name: "campaignInformationModal",
        component: CampaignInformationModal
    },
    campaignPromotionModal: {
        name: "campaignPromotionModal",
        component: CampaignPromotionModal
    },
    onlineCheckoutDiscountModal: {
        name: "onlineCheckoutDiscountModal",
        component: OnlineCheckoutDiscountModal
    },
    expressDiscountModal: {
        name: "expressDiscountModal",
        component: ExpressDiscountModal
    }
};
